 var utils = require('./utils');

 (function(utils) {

    var stickybits = document.querySelectorAll('[data-sticky]');

    utils.forEach(stickybits, function(index, bit) {
        bit.setAttribute('data-original-top', utils.getOffset(bit).top);
    });

    var makeItSticky = utils.debounce(function() {

        utils.forEach(stickybits, function(index, bit) {

            if (window.pageYOffset > bit.getAttribute('data-original-top')) {
                bit.classList.add('is-sticky');
            } else {
                bit.classList.remove('is-sticky');
            }

        });

    }, 10);

    if (stickybits.length) {
        window.addEventListener('scroll', makeItSticky);
    }

 })(utils);
